'use strict';

angular.module('enervexSalesappApp').controller('StackRulesCtrl', function($scope, StackRule, StackRulePhase, _) {
	$scope.all = [];
	$scope.offset = 0;
	$scope.limit = 50;
	$scope.phases = StackRulePhase.query();
	function fetch() {
		StackRule.query({
			offset:$scope.offset,
			limit: $scope.limit
		}).$promise.then(function(items){
			if (items.length > 0) {
				$scope.all = $scope.all.concat(items);
				$scope.offset = $scope.offset + $scope.limit;
				$scope.all = _.sortBy($scope.all, function(o){
					return o.sortOrder * 1
				})
				fetch();
			} else {
				$scope.items = $scope.all
			}
		});
	}
	$scope.phase = ""
	fetch();
	$scope.delete = function(item) {
		StackRule.delete({
			id: item._id
		});
		$scope.all = _.filter($scope.all, function(r){
			return r._id != item._id
		})
		$scope.items = $scope.all;
		// $scope.applyFilter()
	}

	$scope.applyFilter = function() {
		var nameReg = ($scope.term && $scope.term != '') ? RegExp($scope.term, "i") : null;

		$scope.items = _.filter($scope.all, function(item){
			if (item.conditionType == "selected"){
				if (!item.logicsText){
					item.logicsText = item.logics.join("\n")
				}
				if (!item.flagsText && item.flags){
					item.flagsText = item.flags.join("\n")
				}
			}
			if (nameReg && !(nameReg.test(item.action) || nameReg.test(item.logicsText) || nameReg.test(item.flagsText) || nameReg.test(item.filter) || (item.product && nameReg.test(item.product.name)) )) {
				return false;
			} else if ($scope.phase && $scope.phase != '' && $scope.phase != item.phase.name) {
				return false;
			} else if ($scope.flag == 'not-flagged' && item.flags && item.flags.length > 0) {
				return false;
			} else if ($scope.flag == 'flagged' && (!item.flags || item.flags.length == 0)) {
				return false;
			} else {
				return true
			}
		})
	}


	// $scope.applyFilter = function() {
	// 	var nameReg = ($scope.term && $scope.term != '') ? RegExp($scope.term, "i") : null;

	// 	$scope.fans = _.filter($scope.allVentMaterials, function(rule){
	// 		if (nameReg && !nameReg.test(rule.code) ) {
	// 			return false;
	// 		} else if ($scope.computeAs== 'BWH' && !rule.BWH) {
	// 			return false;
	// 		} else if ($scope.computeAs== 'COM' && !rule.COM) {
	// 			return false;
	// 		} else if ($scope.computeAs== 'DRY' && !rule.DRY) {
	// 			return false;
	// 		} else {
	// 			return true
	// 		}
	// 	})
	// }
});
